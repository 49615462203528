export enum EAccordionEvent {
    INIT = 'accordion:init',
    OPEN = 'accordion:open',
    ACTIVATED = 'accordion:activated',
    CLOSE = 'accordion:close'
}

export enum EAccordionEventHandler {
    LAZY_LOAD = 'accordionHandler:lazyLoad'
}
