import lazySizes from 'lazysizes';
import EventBus from 'PyzShopUi/scripts/utils/eventBus';
import {EAccordionEvent, EAccordionEventHandler} from 'PyzShopUi/scripts/modules/events/accordion.event';

lazySizes.cfg.init = false;

export default class LazyLoadHandler {
    public static init(): void {
        const LAZY_LOADED_EVENT = 'lazyloaded';

        const CLASSES = {
            LAZY_IMAGE_CONTAINER: '.lazy-image-container',
            LAZY_IMAGE_LOADED: 'lazy-image-loaded'
        };

        // Load images in viewport only
        lazySizes.cfg.expand = 1;
        lazySizes.cfg.expFactor = 0.001;
        lazySizes.cfg.hFac = 0.001;
        lazySizes.cfg.loadHidden = false;

        document.addEventListener(LAZY_LOADED_EVENT, event => {
            const imageElement = event.target as HTMLElement;
            const imageElementContainer = imageElement.closest(CLASSES.LAZY_IMAGE_CONTAINER);

            if (imageElementContainer) {
                imageElementContainer.classList.add(CLASSES.LAZY_IMAGE_LOADED);
            }
        });

        //Recheck lazyload when accordion opening
        EventBus.getInstance().subscribe(EAccordionEvent.OPEN, (accordionHeader: HTMLElement) => {
            if (accordionHeader.dataset.eventHandler !== EAccordionEventHandler.LAZY_LOAD) {
                return;
            }

            accordionHeader
                .nextElementSibling
                .querySelectorAll('img')
                .forEach(img => lazySizes.loader.unveil(img));
        });

        lazySizes.init();
    }
}
