import jsLogger, { ILogger } from 'js-logger';

class LouisLogger {
    private logger: ILogger;

    constructor(serviceName: string) {
        this.logger = jsLogger.get(serviceName);
        jsLogger.setHandler(jsLogger.createDefaultHandler());

        // @ts-ignore
        if (false) {
            this.logger.setLevel(jsLogger.ERROR);
        } else {
            this.logger.setLevel(jsLogger.TRACE);
        }
    }

    public debug(...x: any[]): void {
        this.logger.debug(...x);
    }

    public info(...x: any[]): void {
        this.logger.info(...x);
    }

    public error(...x: any[]): void {
        this.logger.error(...x);
    }
}

export default LouisLogger;
