import IEventBusSubscription from '../interfaces/Utils/IEventBusSubscription';
import LouisLogger from './LouisLogger';

type CallbackFunction = (...args: any[]) => void;

export default class EventBus {
    public static getInstance(): EventBus {
        if (this.instance === null) {
            this.instance = new EventBus();
        }

        return this.instance;
    }

    private static readonly EVENT_PUBLISHED_MESSAGE = 'Event published';
    private static instance: EventBus = null;
    private readonly subscriptions: object;
    private lastId: number = 0;
    private logger: LouisLogger;

    public constructor() {
        this.subscriptions = {};
        this.logger = new LouisLogger('GlobalEventBus');
    }

    public subscribe = (eventType: string, callback: CallbackFunction): IEventBusSubscription => {
        if (!this.subscriptions[eventType]) {
            this.subscriptions[eventType] = {};
        }

        const id: number = this.getNextUniqueId();

        this.subscriptions[eventType][id] = callback;

        return {
            unsubscribe: (): void => {
                delete this.subscriptions[eventType][id];

                if (Object.keys(this.subscriptions[eventType]).length === 0) {
                    delete this.subscriptions[eventType];
                }
            }
        };
    }

    public publish = (eventType: string, arg?: {}): void => {
        if (!this.subscriptions[eventType]) {
            return;
        }

        if (eventType !== 'window:scroll') {
            this.logger.debug(EventBus.EVENT_PUBLISHED_MESSAGE, eventType, arg);
        }

        Object.keys(this.subscriptions[eventType]).forEach(key => {
            const subscriptionCallback = this.subscriptions[eventType][key];
            if (!subscriptionCallback) {
                return;
            }

            subscriptionCallback(arg);
        });
    }

    private getNextUniqueId = (): number => {
        this.lastId += 1;
        return this.lastId;
    }
}
